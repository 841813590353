<template>
  <div class="partner-container">
    <div class="header-container">
      <div class="header-title">
        <h1 v-html="$t('partner.header.title')"/>
      </div>
    </div>
    <div class="partner-inner-container">
      <div class="partner-gray-background">
        <v-container>
          <v-row class="text-left mt-4 mb-8">
            <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2">
              <v-row>
                <v-col cols="12">
                  <h1 class="partner-title">{{$t('partner.welcome')}}</h1>
                </v-col>
                <v-col cols="12">
                  <div v-html="$t('partner.description')"/>
                </v-col>
                <v-col cols="12">
                  <h1 class="partner-title">{{$t('partner.our')}}
                    <span class="partner-title-primary">{{$t('partner.partners')}} </span>
                  </h1>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
              <partners/>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2" class="mt-5 mb-5">
            <h1 class="partner-title">
              <span class="partner-title-primary">{{$t('partner.program')}}</span>
              {{$t('partner.protection')}}
            </h1>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
            <v-card class="partner-card mb-1">
              <v-row>
                <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="10" offset-xl="1">
                  {{$t('partner.insuranceDescription')}}
                </v-col>
                <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="10" offset-xl="1">
                  <v-btn @click="goToInsurance()" color="primary">
                    {{$t('partner.toProgram')}}
                    <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="footer-arrow">
                    <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                          C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                          C31.4,8.2,31.4,7.9,31.3,7.7"/>
                    </svg>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="8" offset-xl="2" class="mt-12 mb-5">
            <h1 class="partner-title text-center">
              {{$t('support.doYou')}}
              <span class="partner-title-primary">{{$t('support.questions')}}</span>
            </h1>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
            <v-card class="partner-card faq-container mb-1">
              <v-row>
                <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="10" offset-xl="1">
                  <h1 class="partner-title">{{$t('support.hereForYou')}}</h1>
                </v-col>
                <v-col cols="12" sm="12" md="11" offset-md="1" lg="10" offset-lg="1" xl="10" offset-xl="1" class="pt-0">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-btn href="tel:+43484220607">
                        <v-icon left color="primary">
                          {{icons.phone}}
                        </v-icon>
                        +43 4842 20607
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-btn href="mailto:info@ebikeworldfederation.com">
                        <v-icon left color="primary">
                          {{icons.mail}}
                        </v-icon>
                        info@ebikeworldfederation.com
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {mdiEmail, mdiPhone} from '@mdi/js';
import Partners from "../components/Partners";
export default {
  name: 'partner',
  components: {
    Partners,
  },
  data () {
    return {
      icons: {
        mail: mdiEmail,
        phone: mdiPhone,
      },
    }
  },
  methods: {
    goToInsurance() {
      this.$router.push({ name: 'insurance' });
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .partner-container {
    .header-container {
      height: 383px;
      background-color: $primary;
      position: relative;
      .header-title {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 2;
        color: white;
        font-style: italic;
        text-transform: uppercase;
        text-shadow: 2px 2px 4px #000000;
        @include MQ(XS) {
          min-width: 300px;
        }
        h1 {
          font-size: 3.5em;
          line-height: 1;
          @include MQ(XS) {
            font-size: 2em;
          }
          @include MQ(S) {
            font-size: 2em;
          }
          @include MQ(M) {
            font-size: 2.5em;
          }
          @include MQ(L) {
            font-size: 3em;
          }
        }
      }
    }
    .partner-inner-container {
      .partner-gray-background {
        background-color: $basic-gray;
      }
      .partner-title {
        font-weight: 600;
        font-style: italic;
        font-size: 2.2em;
        letter-spacing: 0;
        word-break: keep-all !important;
        text-align: left;
        @include MQ(XS) {
          font-size: 2em;
        }
        .partner-title-primary {
          color: $primary;
        }
      }
      .partner-card {
        background-color: #F5F5F5;
        text-align: left;
        border-radius: 10px;
        .v-btn {
          text-transform: none;
          font-style: italic;
          font-weight: 600;
          height: auto;
          border-radius: 14px;
          padding-bottom: 10px;
          padding-top: 10px;
          -webkit-box-shadow: 0 3px 6px #00000029;
          box-shadow: 0 3px 6px #00000029;
          svg {
            height: 20px;
            width: 30px;
            margin-left: 20px;
          }
        }
        &.faq-container {
          .v-btn {
            box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            background-color: white;
            color: $primary-text;
            font-style: italic;
            font-weight: 600;
            height: 45px;
            width: 100%;
            justify-content: left;
            margin-bottom: 0;
            .v-btn__content {
              text-align: left;
            }
            &:hover {
              color: $primary;
              &::before{
                opacity: 0;
              }
            }
            .v-icon {
              height: 25px;
              width: 25px;
              svg {
                height: 25px !important;
                width: 25px !important;
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
</style>
